import React, { useState } from "react";
import axios from "axios";
import "./Login.css"; // Asegúrate de crear este archivo CSS
import { Button, Spinner } from "@chakra-ui/react"; // Importar Button y Spinner desde Chakra UI

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Estado para manejar el loading

  const handleLogin = async () => {
    setIsLoading(true); // Inicia el loading

    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    try {
      const response = await axios.post(`${backendUrl}/auth/login`, {
        username,
        password,
      });

      const { access_token, id_token, refresh_token } = response.data;

      // Almacenar los tokens en localStorage o en otro lugar seguro
      localStorage.setItem("accessToken", access_token);
      localStorage.setItem("idToken", id_token);
      localStorage.setItem("refreshToken", refresh_token);

      // Redirigir al usuario a la página protegida
      window.location.href = "/products/aura";
    } catch (error) {
      setError("Invalid username or password");
    }
    setIsLoading(false); // Inicia el loading
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="login-image">
          <img src="/assets/images/login_banner.png" alt="Mining" />
        </div>
        <div className="login-form">
          <img
            className="logo"
            src="/assets/images/auraminingdark.png"
            alt="Logo"
          />
          <h2>Bienvenido a Aura Mining</h2>
          <p>
            Estamos aquí para apoyarte en cada paso con la inteligencia de Aura.
          </p>
          <div className="input-group">
            <label>Email</label>
            <input
              type="text"
              placeholder="Email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label>Contraseña</label>
            <input
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="login-actions">
            <div>
              <input type="checkbox" id="remember" />
              <label htmlFor="remember">Recordar sesión</label>
            </div>
            <a href="#">¿Olvidó la contraseña?</a>
          </div>
          <Button
            size="lg"
            isLoading={isLoading} // Controla el estado de loading
            loadingText="Accediendo..."
            onClick={handleLogin}
          >
            Acceder
          </Button>
          {error && <p className="error">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default Login;
