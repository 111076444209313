import React from "react";
import { ChakraProvider, HStack, border, extendTheme } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import LeftBar from "./components/LeftBar";
import MainContent from "./pages/MainContent";
import Login from "./pages/Auth/Login";
import PrivateRoute from "./PrivateRoute";
import Callback from "./pages/Auth/Callback";
import { useTheme } from "./providers/ThemeContext";
import "./global.css";

function RequireAuth({ children }) {
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");

  if (!accessToken) {
    // Redirigir al login si no está autenticado
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children; // Retorna el componente protegido si está autenticado
}

function App() {
  const { isDarkMode } = useTheme();

  const theme = extendTheme({
    styles: {
      global: {
        "::-webkit-scrollbar": {
          width: "8px",
        },
        "::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          borderRadius: "10px",
        },
        body: {
          bg: isDarkMode ? "white" : "#242427",
          // color: "#DBDBDB",
          scrollbarWidth: "thin",
          scrollbarColor: "rgba(255, 255, 255, 0.2) transparent",
        },
      },
    },
    components: {
      Input: {
        variants: {
          filled: {
            field: {
              bg: isDarkMode ? "#F1F3F6" : "#292929",
              color: isDarkMode ? "black" : "white",
              _hover: {
                bg: isDarkMode ? "#F1F3F6" : "#292929",
              },
              _focus: {
                bg: isDarkMode ? "#F1F3F6" : "#292929",
                borderColor: isDarkMode ? "#964F3C" : "#964F3C",
              },
            },
          },
        },
      },
      Button: {
        variants: {
          solid: {
            bg: "#1A1A1A",
            color: "white",
            _hover: {
              bg: isDarkMode ? "#964F3C" : "#964F3C",
            },
          },
        },
      },
      Text: {
        baseStyle: {
          color: isDarkMode ? "black" : "white",
        },
      },
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/callback" element={<Callback />} />
          <Route
            path="/products/aura"
            element={
              <PrivateRoute>
                <HStack spacing={0} height="100vh" alignItems="stretch">
                  <LeftBar />
                  <MainContent isDarkMode={isDarkMode} />
                </HStack>
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Navigate to="/products/aura" />} />
          <Route
            path="*"
            element={
              <RequireAuth>
                <Navigate to="/products/aura" replace />
              </RequireAuth>
            }
          />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
