import React from "react";
import {
  Box,
  VStack,
  Text,
  Button,
  Divider,
  Image,
  HStack,
} from "@chakra-ui/react";
import { FaPen } from "react-icons/fa";
import ThemeToggle from "./ThemeToggle";
import "./LeftBar.css";
import { useBreakpointValue } from "@chakra-ui/react";

const historyItems = [
  "¿Cuáles son los flujos...?",
  "¿Cuál es la capacidad...?",
  "¿Cuál es el coeficiente...?",
];

const assistants = ["Operaciones", "Mantenimiento", "Procesos"];

function LeftBar() {
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Renderiza el LeftBar solo si no es móvil
  if (isMobile) {
    return null;
  }
  return (
    <Box display="flex" height="100vh" width="24%">
      {/* Barra Naranja */}
      <Box className="leftbar-box" />

      {/* Contenedor Principal */}
      <Box className="leftbar-container" width="95%">
        <VStack className="leftbar-vstack">
          {/* Logo Section */}
          <Box className="leftbar-logo">
            <Image src="/assets/images/auramining.png" alt="Company Logo" />
          </Box>

          {/* New Consultation Button */}
          <Box className="leftbar-button">
            <Button _hover="transparent" bg="transparent" leftIcon={<FaPen />}>
              Nueva consulta
            </Button>
          </Box>

          {/* Assistants Section */}
          <Box paddingLeft={5} alignContent="flex-start" alignSelf="flex-start">
            <Text color="white">Asistentes</Text>
            <VStack>
              {assistants.map((assistant, index) => (
                <Button bg="transparent" key={index} w="100%">
                  <HStack spacing="20px" align="center" w="100%">
                    <Image
                      src="/assets/images/bot-assistant.png"
                      boxSize="20px"
                      borderRadius="full"
                    />
                    <Text
                      paddingTop="18%"
                      color="white"
                      flex="1"
                      textAlign="left"
                      align="center"
                    >
                      {assistant}
                    </Text>
                  </HStack>
                </Button>
              ))}
            </VStack>
          </Box>

          {/* Divider */}
          <Divider className="leftbar-divider" />

          <Box paddingLeft={4} alignContent="flex-start" alignSelf="flex-start">
            <Text color="white">Historial</Text>
            <VStack align="stretch" spacing={2}>
              {historyItems.map((item, index) => (
                <Button bg="transparent" key={index}>
                  <HStack spacing={2}>
                    <Text
                      paddingTop="18%"
                      paddingRight="10%"
                      color="white"
                      overflow="hidden"
                      flex="1"
                      textAlign="left"
                      align="center"
                    >
                      {item}
                    </Text>
                  </HStack>
                </Button>
              ))}
            </VStack>
          </Box>

          {/* Push the following content to the bottom */}
          <Box flexGrow={1}></Box>

          {/* Bottom Logo Section and Theme Toggle */}
          <VStack className="leftbar-theme-toggle">
            <Image
              src="/assets/images/logo_leftbar.png"
              className="leftbar-footer-logo"
            />
            <ThemeToggle />
          </VStack>
        </VStack>
      </Box>
    </Box>
  );
}

export default LeftBar;
