import React from "react";
import { Button, Circle, Box, HStack } from "@chakra-ui/react";
import { BiLike, BiDislike } from "react-icons/bi";

const Feedback = () => {
  return (
    <Box>
      <HStack>
        <Circle
          size="40px"
          color="white"
          borderColor="#707070"
          borderWidth={2}
          marginRight={2}
        >
          <BiDislike size="20px" color="#707070" />
        </Circle>
        <Circle size="40px" color="white" borderColor="#707070" borderWidth={2}>
          <BiLike size="20px" color="#707070" />
        </Circle>
      </HStack>
    </Box>
  );
};

export default Feedback;
