import React from "react";
import { Box, Flex, Text, Center } from "@chakra-ui/react";
import { useTheme } from "../providers/ThemeContext";

const UserMessage = (
  { message } // Cambiado a destruct
) => {
  const { isDarkMode } = useTheme();

  return (
    <Flex
      bg={isDarkMode ? "#F1F3F6" : "#27272D"}
      p={3}
      textAlign={"right"}
      position="relative"
      align={"flex-start"}
      justifyContent="flex-end" // Agregado para alinear a la derecha
      borderRadius="md" // Agregado para hacer una burbuja de chat
      width="100%" // Agregado para hacer el contenedor más pequeño
      height="8%"
    >
      <Center>
        <Text alignItems="center" pt={2}>
          {message.text}
        </Text>
      </Center>
      {console.log("message", message.fromNotification)}
      {message.fromNotification && (
        <Box
          position="absolute"
          top="10px"
          left="-90px"
          // bg="#FFD700"
          bg="red"
          color="#000"
          height={10}
          px={1}
          py={1}
          borderRadius="md"
          fontSize="sm"
          fontWeight="bold"
        >
          #PAB001
        </Box>
      )}
    </Flex>
  );
};

export default UserMessage;
