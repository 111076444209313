import React, { useState, useEffect } from "react";

const ImageCard = ({ images }) => {
  const [loadedImages, setLoadedImages] = useState([]);

  useEffect(() => {
    setLoadedImages(Array(images.length).fill(false));
  }, [images]);

  const handleImageLoad = (index) => {
    const newLoadedImages = [...loadedImages];
    newLoadedImages[index] = true;
    setLoadedImages(newLoadedImages);
  };

  return (
    <div align="center">
      {images.map((image, index) => (
        <div
          key={index}
          style={{
            borderRadius: "5px",
            overflow: "hidden",
            marginBottom: "10px",
            paddingBottom: "20px",
            opacity: loadedImages[index] ? 1 : 0,
            transition: "opacity 1s ease-in-out",
          }}
        >
          <img
            src={image}
            alt={`Image ${index + 1}`}
            style={{ width: "50%", height: "auto" }}
            onLoad={() => handleImageLoad(index)}
          />
        </div>
      ))}
    </div>
  );
};

export default ImageCard;
